<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar servicio</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4" class="mt-5">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :counter="190"
                prepend-icon="mdi-octagram"
                label="Nombre *"
              ></v-text-field>
              <v-textarea
                v-model="description"
                :rules="descriptionRules"
                :counter="190"
                prepend-icon="mdi-format-list-group"
                label="Descripción *"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="4"></v-col>           
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <div class="text-right">
              <v-btn color="primary" outlined class="mr-4" @click="cancel" width="125">
                <v-icon>mdi-close-circle</v-icon> Cancelar
              </v-btn>
              <v-btn color="primary" outlined @click="update" width="125"><v-icon>mdi-sync-circle</v-icon> Actualizar </v-btn>
            </div>
            </v-col>           
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    name: "",
    description: "",
    menu1: false,
    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
    nameRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
  }),

  mounted() {
    this.chargeData();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
    },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };

      axios
        .post("/service", request)
        .then((response) => {
          console.log(response.data);
          this.description = response.data.description;
          this.name = response.data.name;
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar datos");
        });
    },
    cancel() {
      setTimeout(() => this.$router.push({ name: "services" }), 10);
    },

    update() {
      console.log("actalizando");

      let request = {
        id: this.$route.params.id,
        name: this.name,
        description: this.description,
      };
      axios
        .post("/update-service", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "services" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "");
          setTimeout(() => this.$router.push({ name: "services" }), 4000);
        });
    },
  },
};
</script>